<template>
  <div class="ps-section__right">
    <form class="ps-form--account-setting" method="get">
      <div class="ps-form__header">
        <h3>Change Password</h3>
      </div>
      <UserForm ref="userForm" :user-id="this.$route.params.id"></UserForm>
    </form>

  </div>
</template>

<script>
import axios from "axios";
import UserForm from "./UserForm";

export default {
  name: "ChangePassword",
  components: {UserForm},
}
</script>

<style scoped>

</style>
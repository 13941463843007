<template>
  <div class="ps-form__content">
    <div class="form-group">
      <label>Name<sup>*</sup> </label>
      <div class="form-group__content">
        <input class="form-control" type="text" v-model="form.name" placeholder="" />
        <small v-if="errors && errors.name" class="phone_error text-danger">
          {{ errors.name[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <label>Email Address<sup>*</sup> </label>
      <div class="form-group__content">
        <input class="form-control" v-model="form.email" type="email" />
        <small v-if="errors && errors.email" class="text-danger">
          {{ errors.email[0] }}
        </small>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Country</label>
          <select
              v-model="form.country"
              class="form-control"
          >
            <option :value="country.id" v-for="country in countries">{{ country.name }}</option>
          </select>
          <small v-if="errors && errors.country" class="text-danger">
            {{ errors.country[0] }}
          </small>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label>City/Town</label>
          <input class="form-control" type="text" v-model="form.city" placeholder="" />
          <small v-if="errors && errors.city" class="text-danger">
            {{ errors.city[0] }}
          </small>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label>Phone<sup>*</sup> </label>
      <div class="form-group__content">
        <input class="form-control" v-model="form.phone_number" type="text" />
        <small v-if="errors && errors.phone_number" class="text-danger">
          {{ errors.phone_number[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <label>Address<sup>*</sup> </label>
      <div class="form-group__content">
        <input class="form-control" v-model="form.street"  type="text" />
        <small v-if="errors && errors.street" class="text-danger">
          {{ errors.street[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <button class="ps-btn" @click.prevent="onSubmit()">
        <i v-if="!loading" class="icon-floppy-disk"></i>
        <span v-if="loading" class="spinner-border text-light" role="status"></span>
        {{ loading ? "Saving..." : "Save" }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FormMixin from "@/mixins/form.js";
export default {
  name: "UserForm",
  props: {
    userId: String
  },
  mixins: [FormMixin],
  data() {
    return {
      countries: [],
      loading: false,
      form: {
        name: "",
        email: "",
        phone_number: "",
        street: '',
        country: '',
        city: '',
        is_default: false,
      }
    }
  },
  created() {
     if (this.userId) {
       this.getUser()
     }
    this.getCountries();
  },
  methods: {
    getCountries() {
      axios.get(`countries`).then((response) => {
        this.countries = response.data.filter(
            (country) => country.zip && country.code
        );
      });
    },

    onSubmit() {
      this.isLoading = true;
      if (this.userId) {
        this.updateUser();
      } else {
        this.createUser();
      }

    },

    createUser() {
      axios.post('users', this.form).then((response) => {
        this.handleSuccess(response, 'User successfully created.', 'admin_users')
      }).catch((error) => {
        this.handleErrors(error, 'User could not be created, please enter all fields and try again.')
      });
    },

    updateUser() {
      axios.post(`users/${this.userId}`, this.form).then((response) => {
        this.handleSuccess(response, 'User successfully updated.', 'admin_users')
      }).catch((error) => {
        this.handleErrors(error, 'User could not be updated, please enter all fields and try again.')
      });
    },

    getUser() {
      axios.get(`users/${this.userId}`).then((response) => {
        this.form = response.data
      });
    }
  }
}
</script>

<style scoped>

</style>